.row-deleted {
  position: relative;
}

.row-deleted:after {
  content: "";
  position: absolute;
  width: 100%;
  display: block;
  height: 1px;
  top: 50%;
  background: #303030;
}

.card-section {
  border: 1px solid rgba(255, 255, 255, 0.12);
  padding: 10px;
  position: relative;
  margin-bottom: 15px;
}

.card-section__title {
  position: absolute;
  top: -10px;
  font-weight: bold !important;
  display: flex;
  align-items: center;
  width: 90%;
}

.card-section__title > button {
  margin-left: auto;
  padding: 0;
  line-height: 1 !important;
  font-size: 14px !important;
}

.card-section__body {
  margin-top: 10px;
}

.card-section:last-child {
  margin-bottom: 0;
}

.list-with-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.list-with-flex__item {
  min-width: 50%;
  font-size: 14px !important;
  position: relative;
}

.list-with-flex__item-full {
  min-width: 100%;
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  padding-bottom: 5px !important;
  margin-bottom: 5px !important;
}

.list-with-flex__item-full:last-child {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
  border-bottom: none;
}

.list-with-flex__value {
  word-break: break-all;
  font-size: 14px !important;
}

.MuiCardContent-root:last-child {
  padding-bottom: 16px !important;
}

.list-with-flex__action {
  position: absolute;
  top: 0;
  right: 0;
}

.list-with-flex__action > button {
  padding: 0;
  line-height: 1 !important;
  font-size: 14px !important;
}
